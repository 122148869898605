<template>
  <div class="box">
    <div class="dlBox" :style="{ transform: `scale(${scale},${scale})` }">
      <div class="logo">
        <img src="../../assets/home/logoTitle.png" />
      </div>
      <img src="../../assets/home/car.png" class="carIcon" />
      <img src="../../assets/home/user.png" class="userIcon" />
      <div class="leftBox">
        <div><img src="../../assets/home/ch.png" alt="" /></div>
      </div>
      <img src="../../assets/home/leftTop.png" class="leftTop" />
      <div class="rightBox">
        <div>
          <div class="section">
            <div class="title">打车助手运营管理平台</div>
            <el-form ref="params" :model="params" :rules="rules">
              <el-form-item prop="userName" class="itemSty top">
                <div class="login-tel login-input">
                  <div class="bg-img"></div>
                  <el-input
                    autocomplete="off"
                    v-model="params.userName"
                    placeholder="请输入手机号码"
                    oninput="if(value.length>11)value=value.slice(0,11)"
                  ></el-input>
                </div>
              </el-form-item>
              <el-form-item prop="code" class="itemSty">
                <div class="login-password login-input">
                  <div class="bg-img"></div>
                  <el-input
                    autocomplete="new-password"
                    ref="password"
                    type="text"
                    onpaste="return false"
                    oncontextmenu="return false"
                    oncopy="return false"
                    oncut="return false"
                    oninput="if(value.length>11)value=value.slice(0,11)"
                    v-model="params.code"
                    placeholder="请输入验证码"
                    maxlength="4"
                    max="9999"
                  >
                  </el-input>
                  <el-button
                    :disabled="validTime != null && validTime != 0"
                    type="text"
                    @click="sendVaild"
                    >{{ validTime | validTimeFilter }}</el-button
                  >
                </div>
              </el-form-item>
              <el-form-item>
                <el-button :loading="buttonLoading" class="btn" @click="login"
                  >登 录</el-button
                >
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="bottom">
        <a class="hover_02" target="_blank" href="https://beian.miit.gov.cn/"
          >陕ICP备2021013914号-1</a
        >
      </div> -->
  </div>
</template>

<script>
import { setLocalStorageItem } from "@/utils/localstorage/LocalstorageKit";
import loginApi from "@/api/LoginApi";
export default {
  name: "Login",
  data() {
    return {
      scale: 1,
      buttonLoading: false,
      sendLoading: false,
      isPassword: false,
      validTime: null,
      timer: null,
      params: {
        userName: "",
        code: "",
      },
      rules: {
        userName: {
          required: true,
          validator: this.validPhone,
          trigger: "blur",
        },
        code: [
          { required: true, message: "验证码不能为空", trigger: "blur" },
          {
            pattern: /^[0-9]{4}$/,
            message: "验证码合适不正确",
            trigger: "blur",
          },
        ],
      },
    };
  },
  filters: {
    validTimeFilter(val) {
      if (val == null || isNaN(val)) return "获取验证码";
      let number = parseInt(val);
      if (number <= 0) {
        return "重新获取";
      } else return number + "S";
    },
  },
  created() {
    this.clickEnter(true);
    this.resize_window();
    window.addEventListener("resize", () => {
      this.resize_window();
    });
  },
  methods: {
    isShowPwd() {
      this.isPassword = !this.isPassword;
    },
    validPhone(rule, value, callback) {
      const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      if (!value) {
        callback(new Error("请输入手机号码"));
      } else if (!reg.test(value)) {
        callback(new Error("请输入正确的手机号"));
      } else {
        callback();
      }
    },
    clickEnter() {
      let that = this;
      document.onkeydown = function (e) {
        let key = window.event.keyCode;
        if (key === 13) {
          that.login();
        }
      };
    },
    sendVaild() {
      const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
      let value = this.params.userName;
      if (!value) {
        this.$errorMsg("请输入手机号码");
        return;
      } else if (!reg.test(value)) {
        this.$errorMsg("请输入正确的手机号");
        return;
      }
      if(this.sendLoading){        
        return;
      }
      this.sendLoading = true;
      loginApi
        .sendLoginSMS({ phone: value })
        .then((resp) => {
          if (resp.code == "200") {
            this.$message.success("验证码发送成功");
            clearInterval(this.timer);
            this.validTime = 60;
            this.timer = setInterval(() => {
              this.validTime -= 1;
              if (this.validTime <= 0) {
                clearInterval(this.timer);
              }
            }, 1000);
          } else {
            this.$errorMsg(resp.msg);
          }
          this.sendLoading = false;
        })
        .catch((err) => {
          this.$errorMsg(err);
          this.sendLoading = false;
        });
    },
    login() {
      this.$refs["params"].validate(async (valid) => {
        if (valid) {
          this.buttonLoading = true;
          loginApi
            .loginWithSMS(this.params)
            .then((resp) => {
              if (resp.code == "200") {
                setLocalStorageItem("token", resp.data.token);
                window.location.href = "/home";
              } else {
                this.$errorMsg(resp.msg);
              }
              this.buttonLoading = false;
            })
            .catch((err) => {
              this.buttonLoading = false;
              this.$errorMsg(err);
            });
        } else {
          return false;
        }
      });
    },
    resize_window() {
      this.scale = Number(document.documentElement.clientWidth / 1920);
    },
  },
};
</script>
<style  scoped>
.box {
  width: 100%;
  height: 100%;
  user-select: none;
  background: url(../../assets/home/bg.png) no-repeat;
  background-size: cover;
  position: relative;
  text-align: center;
  background-attachment: fixed;
}
.logo {
  display: flex;
  justify-content: baseline;
  position: absolute;
  height: 64px;
  margin: 80px 0 0 306px;
}
/* .logo img:first-child {
  width: 43px;
  height: 42px;
} */
.logo img:last-child {
  width: 178px;
  height: 42px;
  margin: 5px 8px;
}
.dlBox {
  position: absolute;

  transform-origin: left top;
}
.leftTop {
  width: 297px;
  height: 186px;
  position: absolute;
  left: 170px;
  top: 353px;
}
.carIcon {
  width: 65px;
  height: 75px;
  position: absolute;
  left: 547px;
  top: 246px;
}
.userIcon {
  width: 46px;
  height: 44px;
  position: absolute;
  left: 881px;
  top: 320px;
}
.leftBox {
  width: 941px;
  height: 446px;
  position: absolute;
  left: 214px;
  top: 415px;
}
.rightBox {
  width: 480px;
  height: 389px;
  margin: 294px auto auto 1220px;
  background: white;
  box-shadow: 0px 4px 18px 2px rgba(174, 178, 184, 0.31);
  border-radius: 16px;
}
.leftBox div,
.rightBox div {
  width: 100%;
}
.leftBox img {
  width: 100%;
  height: auto;
}
.section {
  width: 86%;
  box-sizing: border-box;
  padding: 40px;
}
.title {
  width: 330px;
  height: 40px;
  font-size: 30px;
  font-weight: bold;
  color: #4f4d46;
  line-height: 40px;
}
.login-input {
  position: relative;
  /* margin: 0 auto; */
  /* width: 70%!important; */
}
.itemSty {
  height: 48px;
  margin-top: 24px;
}
.bg-img {
  width: 20px !important;
  height: 20px;
  position: absolute;
  left: 20px;
  top: 10px;
  z-index: 999;
}
.login-password {
  position: relative;
}
login-password > .bg-img {
  background: url("../../assets/home/password.png") no-repeat;
}
.login-password > .el-button {
  position: absolute;
  display: inline;
  height: 20px;
  text-align: right;
  top: 2px;
  right: 0;
  line-height: 20px;
  right: 10px;
  font-size: 14px;
}
::v-deep .is-disabled span {
  cursor: default;
}
>>> input {
  padding-left: 16px;
  height: 48px;
  color: #333333;
  font-size: 14px;
  border: 1px solid #dddddd;
  border-radius: 8px;
}
.btn {
  width: 100%;
  height: 56px;
  background: linear-gradient(360deg, #2c6aff 0%, #06a3ff 100%);
  box-shadow: inset 0px 0px 1px 0px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  font-size: 20px;
  font-family: Helvetica;
  color: #ffffff;
  margin-top: 32px;
}
.top {
  margin-top: 40px;
}
.bottom {
  width: 100%;
  position: absolute;
  bottom: 20px;
  text-align: center;
}
.hover_02 {
  text-decoration: none;
  color: black;
}
.hover_02:hover {
  color: #0286df !important;
}
</style>